import {reactive, toRefs, defineComponent,onBeforeMount,onMounted,getCurrentInstance,nextTick} from 'vue';
import OrderCardUtil,{IOrderCardDataObj} from './orderCardUtil';
import Order from '@/views/project/order/utils/order/Order.vue';

export default defineComponent({
    name:'OrderCard',
    title:'订单详情',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IOrderCardDataObj=reactive<IOrderCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/order')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {},
            otherParams:{
                order:{},//控制订单哪些熟悉显示/影藏的对象
                processInfo:{//审核对象
                    visible:false,//是否显示弹出框
                    status:1,//审核结果（1-不通过 2-通过）
                    shDesc:'',//审核附言
                },
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new OrderCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            if('/load'==addOrLoad){
                dataObj.otherParams.order={priceFlag:false,sumAmountFlag:false,numEditFlag:false,pccFlag:true,
                    otherInfoFlag:false,addBtnFlag:false,disableCustomer:true,customerDelivery:true,disablePcc:true};
                nextTick(()=>{//必须放到nextTick里面，否则order组件可能还未初始化好
                    dataObj.refMap.get('order').otherParams.orderData=JSON.parse(res.data.jsonContent);
                    dataObj.refMap.get('order').utilInst.initCxCxgghData();
                })
            }else{
                dataObj.otherParams.order={priceFlag:false,sumAmountFlag:false,numEditFlag:true,pccFlag:true,
                    otherInfoFlag:false,addBtnFlag:true,disableCustomer:false,customerDelivery:false,disablePcc:false};
            }
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=async ()=>{
            return dataObj.utilInst.checkSaveData();
        }
        //通过按钮点击
        const passHandler=()=>{
            dataObj.otherParams.processInfo={status:2,visible: true,shDesc: ''};
        }
        //不通过按钮点击
        const notPassHandler=()=>{
            dataObj.otherParams.processInfo={status:1,visible: true,shDesc: ''};
        }
        //审核订单确定事件
        const processOrder=async()=>{
          await dataObj.utilInst.doOrderSure();
        }
        //导出
        const exportExcelHandler=()=>{
            const loading = proxy.$loading({lock: true,text: '请等候......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            let data:any=[];
            let orderCode=dataObj.form.code;//意向订单号
            let tjTime=dataObj.form.tjTime;//提交时间
            let orderData=dataObj.refMap.get('order').otherParams.orderData;
            let customer=orderData.customer;
            let customerCode=customer.code;//客户编码
            let customerName=customer.customerId.label;//客户名称
            let countryName=customer.countryName;//客户国家
            orderData.cfgData.forEach((item1:any)=>{
                item1.itemChildren.forEach((item2:any)=>{
                    let cxName=item2.header.cxName;//车型名称
                    let cxggh=item2.header.cxggh;//车型代码
                    let enDesc=item2.header.enDesc;//配置名称
                    item2.children.forEach((item3:any)=>{
                        let color=item3.color;//颜色
                        let num=item3.num;//数量
                        let dataItem=[orderCode,tjTime,customerCode,customerName,countryName,cxName,cxggh,enDesc,color,num];
                        data.push(dataItem)
                    })
                })
            });
            let labels=['意向订单号','提交日期','客户编码','客户名称','客户国家','车型名称','车型代码','配置名称','颜色','数量'];
            proxy.excelUtils(labels, data, '意向订单' + utils.UtilPub.getCurrentDate());
            proxy.$message({showClose: true, message: proxy.$t('listEngine.exportSuccess'), type: 'success'});
            loading.close();
        }
        return{
            ...toRefs(dataObj),proxy,beforeOpen,beforeSaveHandler,passHandler,notPassHandler,processOrder,exportExcelHandler
        }
    },
    components: {
        Order
    }
});