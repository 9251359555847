import BaseBean from "@/utils/BaseBean";
import _ from 'lodash';
export interface IOrderCardDataObj {
    utilInst:OrderCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class OrderCardUtil extends BaseBean{
    public dataObj:IOrderCardDataObj;

    constructor(proxy:any,dataObj:IOrderCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
    }
    //检查保存的数据是否合法
    public checkSaveData():boolean{
        let orderData=this.dataObj.refMap.get('order').otherParams.orderData;
        let orderDataBak = _.cloneDeep(orderData);
        if(!orderData.customer.customerId || !orderData.customer.customerAccountId || !orderData.customer.customerDeliveryId){
            this.utils.Tools.info({message:'客户名称、客户账号、收货人不能为空'});
            return false;
        }
        if(!orderData.cfgData || orderData.cfgData.length==0){
            this.utils.Tools.info({message:'请添加产品'});
            return false;
        }
        //去掉数量为0的项
        let cfgData=orderData.cfgData.filter((item1:any)=>{
            let _item1=item1.itemChildren.filter((item2:any)=>{
                let _item2=item2.children.filter((item3:any)=>item3.num!=0);
                item2.children=_item2;
                item2.header.priceHis=[];//添加人名币单价修改历史，后面用
                item2.header.priceUsdHis=[];//添加美元单价修改历史，后面用
                return item2.children.length!=0;
            })
            item1.itemChildren=_item1;
            return item1.itemChildren.length!=0;
        })

        if(cfgData.length==0){
            this.utils.Tools.info({message:'没有配置任何产品'});
            this.dataObj.refMap.get('order').otherParams.orderData=orderDataBak;
            return false;
        }
        orderData.cfgData=cfgData;
        this.dataObj.form.orderData=orderData;

        //保存订单的时候，在订单那里设置showUs为1的默认值，哪怕我们otherInfoFlag设置为false，在进入订单界面的时候，
        // 还是把showUs和组件进行双向绑定设置成为了0，所以在保存订单这里把默认值设置回去吧
        this.dataObj.form.orderData.otherInfo.showUs="0";
        return true;
    }
    //订单审核确认时间
    public async doOrderSure():Promise<void>{
        let res=await this.utils.OrderProviderApi.processOrder({
            params:{
                status:this.dataObj.otherParams.processInfo.status,
                shDesc:this.dataObj.otherParams.processInfo.shDesc,
                id:this.dataObj.form.id
            }
        });
        if(res.result){
            this.utils.Tools.success();
            this.dataObj.otherParams.processInfo.visible=false;
            this.proxy.engine.engineParams.ownerComp.queryHandler(false)
            this.proxy.dialogInst.dialogVisible=false;
        }
    }
}